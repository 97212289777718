import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../../../../components/structure/layout'

const TriumphOfTheOrcishHordesPage = () => (
  <Layout>
    <Helmet title="Triumph of the orcish hordes - Warcraft Fiction" />
    <section>
      <h1>Triumph of the orcish hordes</h1>

      <div className="disclaimer-container">
        <span className="year">~ year: 2002 | Georgi's age: 17</span>
        <span className="disclaimer">
          Unedited things the way they were in the late 90s and early 2000s
        </span>
      </div>

      <div>
        <pre>
          Finally the great day approached - the Orcish Hordes attacked upon the
          once prosperous human world of Azeroth. First it was the dark shaman -
          Ner'zhul, who opened the rift between his cursed world of Draenor, and
          that of the humans. Now, after so many years passed, while he was
          communicating with the same dark necromantic human mage that helped
          him with the opening of the rift, called Medivh, we stand ready and
          now in the human year 583 we will strike only one but final blow
          against those weak fools. We used this foolish enough mage to learn
          much about the human culture.
          <br />
          Their elders say that thousands of years ago, Dragons ruled Azeroth.
          The other continent, called Kalimdor, was the homeworld of the race of
          the Night Elves. The Dragons used to protect this world from the
          demonic consuming powers of the Burning Legion. Those strange
          creatures were led by their commander - lord Sargeras - lord of
          terror, hatred and destruction, consuming thousands of worlds until
          now�
          <br />
          It is also said that they could enter your realm only if you have used
          dark destructive spells. That was just what the Night Elves did, but
          without realizing it. Once the Burning Legion arrived he left
          destruction in his wake, hatred flew in the air above him, and terror
          cleaned the ground from all living creatures, until he met the
          Dragons. These two powerful races fought for generations, until one
          day, the Dragon Queen Alexstrasza took the command over the Dragon
          forces. She led them in hundreds of glorious battles and after
          banishing the daemons from this world, they entombed their leader in a
          massive tomb in the middle of the Great Sea. The Night Elves, however,
          having knowledge of what they have done, moved towards the lands of
          Quel'thalas, and uniting with the dwarven lords become the so called
          High Elves, who promised not to use magic ever again.
          <br />
          It is our time now. We must go through the Dark Portal, opened by
          Ner'zhul, and then seek the hidden tomb of Sargeras. Once we free him,
          Ner'zhul believes he could use Sargeras' demonic skills for our own
          purposes. With the help of this newfound powerful ally, none shall
          stop us from destroying the humans and opening new rifts to other
          worlds as well.
          <br />
          I, the writer of this book - Kil'jaeden - warchief of the Laughing
          Skull Clan, say that tomorrow the first orc will be sent through the
          portal. Ner'zhul chose the leader of the Warsong Clan - Grom
          Hellscream to test the effects of the portal travel, and in the same
          time manage to solve a serious task. Grom must not only travel
          successfully, but also seek the human traitor-mage Medivh. Only by
          killing him we could be sure of a safe travel. Otherwise we cannot
          risk the life of our shaman warchief.
          <br />
          This is it! Grom has just gone through the portal. Now the only thing
          we could do is to wait and pray that he succeeds in his mission.
          Ner'zhul said that Hellscream will have no more that 3 days. After
          that the dark shaman will seek contact with Medivh and all will be
          lost...
          <br />
          <br />
          ***
          <br />
          <br />
          Today, after 5 days and a half long and tiring waiting, Grom
          Hellscream returned. This day shall be our most important one, or as
          the humans say national holiday. As Grom Hellscream said - Medivh is
          dead and the rift is still opened. The year is 583, the day 219. Many
          generations will have knowledge of this great day. This will probably
          be our triumph, the triumph of the Orcish Hordes. Unfortunately I
          wasn't able to talk to Hellscream yet. The shaman asked him for a
          private meeting. Tomorrow, however, we will hear our destiny...
          <br />
          <br />
          ***
          <br />
          <br />
          As I went through the Dark Portal - Grom Hellscream began his noble
          victorious story, - I was stunned and shadows crawled over me. The
          spirits of the Twisting Nether were taking me with them. I just passed
          out and ... the next time I woke up I was in the middle of a huge
          green field. The Portal was right behind me, so I thought my trip was
          successful. As I looked up I saw awful things in the sky - its color
          was perfect blue, with a giant orange-yellow ball that was extremely
          powerful. Perhaps that was the Day Moon of this world? What kind of
          world is this place anyway?! Everything is shiny, lightful and ...
          green. Damn, I didn't think this quest was going to be so difficult...
          <br />
          My first, but unwanted stop was a small village on my way to the human
          tower-town of Stromgarde, where I was supposed to meet Nielas Aran -
          royal conjurer who is told to know the location of Medivh's mage
          tower. So I guess my primary objective was to slay Medivh himself, but
          on that quest I did need some serious help though. May be this Aran
          guy will know of someone worthy, or at least he will help me himself.
          But all those thoughts should have been very far from me. The human
          peons, called unknown why "peasants", welcomed me very well. They said
          they've never before saw orc so I told them a little bit about our
          once shamantic culture. Those humans have interesting food, though!
          They eat the free and flying birds, the green bushes and other
          green-like plants. I started to have second thoughts about their
          membership of the mammal kind. They are so weak, pathetic, but in the
          same time so proud, honorable. I also did get accustomed to living in
          this green and light world. Perhaps this wasn't so bad world after
          all... The time has come, after those 2 day, to depart for Stromgarde.
          The peasants gave me map, because they thought I was going to meet
          their king and thus talk about my orcish nation to decide our destiny.
          <br />
          Whole one day I spent walking, always to the west. Finally when the
          Day Moon was setting down, I saw in the far distance gigantic and
          really high towers. I decided to sneak when the Day Moon fully
          disappears. After a short and needed rest, until the Day Moon was
          completely gone, I proceeded forward to my destiny. It was easy to
          find Aran - the peasants told me where I could find him. Just as our
          shamans said, Nielas already knew of my visit and quest. We quickly
          managed to understand what each one of us wanted, because the target
          was common. He wanted, just like me, to slay Medivh for betraying his
          nation. Nielas said that Medivh was dealing with dark necromantic
          spells, so this point was important for me. I really started to think
          whether it is right to free so powerful demon, like Sargeras, and
          ensure the doom of this world. That, however, was another far from me
          thought at that time, though. One thing was sure - we were going to
          slay Medivh.
          <br />
          The same evening, at midnight, we went outside Stromgarde and in
          friendly and pleasant conversation we walked to the north until the
          Day Moon, which Nielas told me to call SUN, rised again. We approached
          the dark magicion's tower. The guards were still sleeping, no magic
          traps occured, it looked like Medivh expected no assault. Again I
          remembered how careless were the humans, although I realized there are
          different types of people. Their nations and even towns aren't so
          united as the Orcish Hordes are, but there are people who are
          trustworthy, honorable and generous. I liked those! They reminded my
          of the Horde what it used to be before that stupid necromancer
          Ner'zh... what was I doing? I don't know. But feeling of contradiction
          between my old self and the nowadays image of me appeared. Perhaps the
          spirit of Sargeras didn't need to be freed. Perhaps it was free
          already. Questions, question and questions and again and...
          <br />
          -What are you doing? - Nielas looked through my eyes deep in my soul.
          I know he knew what I thought.
          <br />
          -It's nothing. - I answered coldly and strong enough to stop any
          further questions - Look! The SUN is about to rise. Let's not waste
          time.
          <br />
          The next 15 minutes were as swift as nothing else was. I just didn't
          even understand what happened. It was just some 15 minutes of hard
          thinking. Decisions were needed to be taken. During all that time
          Nielas showed me the room of Medivh. He said I had to go alone and
          kill the traitor. I did so. I just entered and stabbed the bastard
          with my obsidian orcish SHAMANTIC knife. Again I remembered of the old
          shamantic days of my tribe. I was filled with anger. The one thing
          that woke me up from my thoughts was Medivh's death. It was the most
          terrible thing I've ever seen. He kept cursing and trying to hold my
          hand while the dark shadows of the Nether were slowly draging him in
          the infinite circle of time. This horrific moment passed, though.
          Nielas entered the room and asked me whether I thought about Sargeras
          again. I was stunned. This conjurer was not only conjurer after all.
          Why did he know what was happening in my soul? What were the problems
          that tormented me.
          <br />
          -Yes, I did! And I decided, too. None shall free the demon. Even if I
          have to fight against him.
          <br />
          -That was just what I wanted to hear my ... friend. You chose the way
          of the light. You may feel proud, and your children will surely do.
          <br />
          There was nothing left to say, so we quietly moved away from the
          tower. The guards still were sleeping, and still there were no magic
          traps. Just one doomed soul found its proper place. It was the early
          morning of day 4. The trip was not as fast as the first one. I noticed
          strange matter, though. My companion was kind of gaining powers as we
          walked further and further into the green woods. He then told me he
          was elf, and further more father of Medivh. Questions, again appeared,
          but he read them directly from my mind like from an open book. He told
          me how Sargeras, before he was entombed within the Great Sea,
          corrupted Medivh - the son of Nielas Aran and Aegwyn the Protector of
          this world. That was why Medivh was closed in his own tower, fighting
          the necromantic and demonic powers within him. He chose the wrong way,
          though. The way of evil. That made up my final decision.
          <br />
          Half day after we were at the Portal. I told Aran to close the portal
          when I give him sign. On second thought I could give him no sign from
          Draenor, so everything had to be perfectly good done. I told him to
          close the rift exactly 27 hours after I enter it. After a quick good
          bye with this surely noble man I followed my destiny. Now, just after
          some stupid 30 minutes, members of the Horde, Ner'zhul shall enter the
          Dark Portal, and as the leader of the Orcish Hordes will lead us to
          the human world of Azeroth to bring destruction to the humans and doom
          to the whole world of Azeroth. I have already chosen my destiny. It is
          your time now. You may either tell my whole story to Ner'zhul, because
          when yesterday I was with him I lied that everything is ready and
          Sargeras should be easily freed. This option will get me easily
          killed. The other way is to keep this in really deep secret for
          approximately 20 minutes. After that me or Kil'jaeden will take the
          role as SHAMANTIC warchief of the Horde.
          <br />
          -Liopta
          <br />
          -Liopta
          <br />
          -Dabu
          <br />
          As I, Kil'jaeden, and the rest of the orcish hordes heard this we all
          without even think for a second shouted positive answers to Grom's
          question. Some said Dabu, others Liopta, but all agreed his plan. And
          so we decided to wait and see.
          <br />
          <br />
          ***
          <br />
          <br />
          Ner'zhul that necromantic bastard is entering the Portal. As he makes
          his first step we will be free once again to return to our positive
          shamantic culture. Ner'zhul's first step will be the Horde's largest
          journey into the land of the free. And here he goes. With his face
          smiled with grim and evil smile, he disappeares into the Nether. Now
          all is up to Nielas Aran. No one knew why, but happily just after 2
          minutes the rift exploded and Ner'zhul was trapped into the Twisting
          Nether for ever. The face of Nielas pictured into the Night Moon of
          Draenor. That was when this same Night Moon was called Aran, after the
          name of this great "royal conjurer". As for the Orcish Hordes, we
          lived for eternity in the Red World of Draenor. The Warsong and the
          Laughing Skull clans were the leaders and they first returned the cult
          to their abandoned shaman culture, then they were followed by the
          lesser clans, and this was the Triumph of the Orcish Hordes.
        </pre>
      </div>
      <Link to="/">Go back to the homepage</Link>
    </section>
  </Layout>
)

export default TriumphOfTheOrcishHordesPage
